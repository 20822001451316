$(function(){
	// logoの位置移動アニメーションは1回のみ,リサイズ時に発火するのを防ぐ
	setTimeout(function(){
		$('#logo').removeClass('start');
	},6000);
	// news 
	var num = $('.listNews dt').length,
		target = $('#secNews');
	if(num > 1){
		target.append('<p class="btnReadmore"><span>Read<br>More</span></p>');
		$(document).on('click', '.btnReadmore', function(){
			if(target.hasClass('show')){
				target.removeClass('show');
			}else{
				target.addClass('show');
			}
		});
	}
	// 動画を画面サイズによって切り替え
	var v = $('#video');
	if(window.matchMedia('(min-width: 1025px)').matches){
		var src = v.data('pc');
	}else if(window.matchMedia('(min-width: 768px)').matches){
		var src = v.data('tab');
	}else{
		var src = v.data('sp');
	}
	v.attr('src', src);
	v.on('loadeddata', function(){
		v.get(0).play();
	});
	// エレベーターメニュー
	var $win = $(window),
		pageIndex = $('#pageIndex li a'),
		contentArr = new Array(),
		winH = $win.height();
	for(i=0; i < pageIndex.length; i++){
		var indexLink = pageIndex.eq(i).attr('href');
		if(indexLink.charAt(0) == '#') {
			contentArr[i] = [$(indexLink), 0, 0];
		}
	}

	// 可視範囲に入ったらアニメーション
	$('.effect').addClass('js');
	var $pagetop = $('#btnPagetop'),
		$news = $('#secNews');
	$win.on('load resize scroll', function (){
		$(".effect").each(function(){
			var imgPos = $(this).offset().top,
				scroll = $win.scrollTop(),
				windowHeight = $win.height();
			if (scroll > imgPos - windowHeight + windowHeight/5){
				$(this).addClass('start');
			} else {
				$(this).removeClass('start');
			}
		});
		if($win.scrollTop() > 200) {
			$pagetop.addClass('show');
			$news.addClass('show');
		}else{
			$pagetop.removeClass('show');
			$news.removeClass('show');
		}
	});
	$pagetop.on('click', function(){
		$('html,body').animate({scrollTop:0}, 400, "swing");
	});


	// セクションの開始位置と終わり位置をチェックする
	function measureSec(){
		for(i=0; i < contentArr.length; i++){
			var target = contentArr[i][0];
			var secTop = $(target).offset().top - (winH / 3);
			var secBottom = secTop + $(target).outerHeight(true) - 1 - (winH / 3);
			contentArr[i] = [target, secTop, secBottom];
		}
	}
	// measureSec();
	// 現在地をチェックする
	function currentCheck(){
		var top = $win.scrollTop();
		for (var i = 0; i < contentArr.length; i++){
			if(contentArr[i][1] <= top && contentArr[i][2] >= top){
				pageIndex.removeClass('active');
				pageIndex.eq(i).addClass('active');
				i == contentArr.length;
			}
		}
	}
	$win.on('load resize scroll', function(){
		measureSec();
		currentCheck();
	});
	$('a[href^="#"]').click(function() {
		var speed = 400;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$('html,body').animate({scrollTop:position}, speed, "swing");
		return false;
   });
	var $btnNav = $('#btnNav'),
		$target = $('#gNav');
	$btnNav.on('click', function(){
		if($(this).hasClass('on')){
			$(this).removeClass('on').addClass('end');
			$target.removeClass('show').addClass('end');
		}else{
			$(this).addClass('on').removeClass('end');
			$target.removeClass('end').addClass('show');
		}
	});
	// エレベーターメニューここまで
	// 実績紹介スライダー
	$('.wpWorks').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: true,
		prevArrow : '<button type="button" class="slick-prev"><span>Prev</span></button>',
		nextArrow : '<button type="button" class="slick-next"><span>Next</span></button>',
		dots: true,
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
	// メール
	var txt1 = 'info',
		txt2 = 'friend-marketing.co.jp',
		html;
	html = '<a href="mai'+'lto:'+txt1+'@'+txt2+'" class="word-break">'+txt1+'@'+txt2+'</a>';
	$('.cntMail').each(function(){
		$(this).append(html);
	});
	// 地図
	$(window).on('load',function(){
		function initMap() {
			var mapstyle = [
			  {
			    "stylers": [
			      {
			        "lightness": 5
			      }
			    ]
			  },
			  {
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#eaf2f3"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.icon",
			    "stylers": [
			      {
			        "visibility": "off"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#616161"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.text.stroke",
			    "stylers": [
			      {
			        "color": "#f5f5f5"
			      }
			    ]
			  },
			  {
			    "featureType": "administrative.land_parcel",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#bdbdbd"
			      }
			    ]
			  },
			  {
			    "featureType": "landscape.man_made",
			    "elementType": "labels",
			    "stylers": [
			      {
			        "visibility": "on"
			      }
			    ]
			  },
			  {
			    "featureType": "poi",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#d2e4e6"
			      }
			    ]
			  },
			  {
			    "featureType": "poi",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#757575"
			      }
			    ]
			  },
			  {
			    "featureType": "poi.park",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#d2e4e6"
			      }
			    ]
			  },
			  {
			    "featureType": "poi.park",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  },
			  {
			    "featureType": "road",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#ffffff"
			      }
			    ]
			  },
			  {
			    "featureType": "road.arterial",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#757575"
			      }
			    ]
			  },
			  {
			    "featureType": "road.highway",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#a5dabb"
			      }
			    ]
			  },
			  {
			    "featureType": "road.highway",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#616161"
			      }
			    ]
			  },
			  {
			    "featureType": "road.local",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.line",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#009d43"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.station",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#009d43"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.station",
			    "elementType": "labels",
			    "stylers": [
			      {
			        "color": "#84bfb4"
			      }
			    ]
			  },
			  {
			    "featureType": "water",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#a8bbbd"
			      }
			    ]
			  },
			  {
			    "featureType": "water",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  }
			]
			var lat = $('#gmap').data('latitude');
			var lng = $('#gmap').data('longitude');
			var mapPosition = new google.maps.LatLng( lat, lng );//緯度経度
			var map = new google.maps.Map(document.getElementById('gmap'), {
				zoom: 16,//ズーム
				center: mapPosition,
				styles:mapstyle
			});
			var icon = {
				url: "/common/img/icon_pin01.svg",
				anchor: new google.maps.Point(15,46),
				scaledSize: new google.maps.Size(30, 46)
			}
			var marker = new google.maps.Marker({
				position: mapPosition,
				map: map,
				draggable: false,
				icon: icon
			});
		}
		if($('#gmap').length){
			initMap();
		}
	});
});